<template>
  <el-drawer
    :title="title"
    :visible.sync="setShow"
    direction="rtl"
    @close="closeEvent"
  >
    <div class="el-drawer--content" v-loading="loading">
      <div class="el-drawer--content__body">
        <div
          v-for="(property, index) in properties"
          :key="index"
          class="property"
        >
          <span>{{ property.label }}</span>
          <el-tag
            v-if="property.type === 'tag'"
            :type="setType(property.value)"
          >
            {{ formatToTitleCase(property.value) }}
          </el-tag>
          <p v-else>{{ property.value }}</p>
        </div>
        <div class="batch-information--section">
          <div class="title">
            <p>Message</p>
          </div>
          <div class="message">
            <p>{{ batch.message }}</p>
          </div>
        </div>
        <bulk-message-recipients
          :id="batch.id"
          :status="batch.status"
          :message="batch.message"
          :recipients="batch.recipients"
          @message-resent="getBulkMessages"
        />
      </div>
    </div>
  </el-drawer>
</template>

<script>
import messages from "@/requests/communications/messages";
import BulkMessageRecipients from "@/components/Communications/BulkMessages/BulkMessageRecipients";

export default {
  name: "BulkMessageInformation",
  components: { BulkMessageRecipients },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      batch: {},
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return `Batch ${this.message.id}`;
    },
    properties() {
      return [
        {
          label: "Batch Id",
          type: "text",
          value: this.batch.id,
        },
        {
          label: "Sender",
          type: "text",
          value: this.batch.sender_id?.name,
        },
        {
          label: "Status",
          type: "tag",
          value: this.batch.status,
        },
        {
          label: "Date & Time Sent",
          type: "text",
          value: `${this.formatDate(
            this.batch.created_at,
            "m do, y",
          )} ; ${this.formatTime(this.batch.created_at, "12h")}`,
        },
      ];
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.getBulkMessage();
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.$emit("close");
    },
    getBulkMessage(val = "") {
      if (!val) {
        this.loading = true;
      }
      const { created_at, id } = this.message;
      const start_date = this.formatDate(created_at, "y-m-d");

      messages
        .message(start_date, id)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.batch = data[0];
            this.$emit("update-batch", this.batch);
          }
          this.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.batch = {
            ...this.message,
          };
          this.loading = false;
        });
    },
    getBulkMessages() {
      this.$emit("reload");
      this.setShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-drawer--content {
  margin-bottom: 0;
  height: 100%;

  &__body {
    height: 100%;
  }
}
.property {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  span {
    font-size: 0.875rem;
    color: var(--eden-grey-tertiary);
  }

  p {
    font-size: 0.875rem;
  }
}

.batch-information--section {
  margin-top: 30px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--eden-grey-septenary);

    p {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

.message {
  border: 1px solid var(--eden-grey-senary);
  padding: 12px;
  border-radius: 8px;

  p {
    font-size: 0.875rem;
    line-height: 1.8;
  }
}
</style>
