<template>
  <div class="home">
    <eden-page-header :title="'Bulk Messaging'" />
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="getBulkMessages"
        />
        <bulk-messages-filter
          :class="'ml-10'"
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <eden-loader v-if="loading" />
      <template v-else>
        <template v-if="pageData.length">
          <el-table :data="pageData">
            <el-table-column width="120">
              <template slot="header">
                <span>Batch Id </span>
              </template>
              <template v-slot="scope">
                <span
                  class="text-primary text-cursor"
                  @click="viewMessage(scope.$index)"
                >
                  BATCH{{ scope.row.id }}</span
                >
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <span>Sender </span>
              </template>
              <template v-slot="scope">
                <span>
                  {{ scope.row.sender_id && scope.row.sender_id.name }}</span
                >
              </template>
            </el-table-column>
            <el-table-column width="120">
              <template slot="header">
                <span>Recipients</span>
              </template>
              <template v-slot="scope">
                <span
                  class="text-primary text-cursor"
                  @click="viewMessage(scope.$index)"
                  >{{ scope.row.recipients_count }} Customers</span
                >
              </template>
            </el-table-column>
            <el-table-column min-width="300">
              <template slot="header">
                <span>Message</span>
              </template>
              <template v-slot="scope">
                <span class="font-sm message">{{ scope.row.message }}</span>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <span class="eden-table-header">Status</span>
              </template>
              <template v-slot="scope">
                <span class="font-sm table--status">
                  <el-tag :type="setType(scope.row.status)">
                    {{ formatToTitleCase(scope.row.status) }}
                  </el-tag>
                </span>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <span class="eden-table-header">Date & Time</span>
              </template>
              <template v-slot="scope">
                <span>{{
                  formatDate(scope.row.created_at, "dddd do, m, y")
                }}</span>
                <span class="is-block">{{
                  formatTime(scope.row.created_at, "12h")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column>
              <template v-slot="scope">
                <el-dropdown @command="command" class="more">
                  <span class="el-dropdown-link more">
                    <i class="eden-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="{
                        action: 'view',
                        index: scope.$index,
                        message: scope.row,
                      }"
                    >
                      View message details</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="scope.row.status != 'pending'"
                      :command="{
                        action: 'resend',
                        index: scope.$index,
                        message: scope.row,
                      }"
                    >
                      Resend Message</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <eden-pagination
            :from="from"
            :to="to"
            :total="total"
            :page-size="perPage"
            :current-page.sync="page"
          />
        </template>
        <eden-content-empty v-else :text="'No messages'" />
      </template>
    </template>
    <bulk-message-information
      :show.sync="showBulkMessageInformation"
      :message="message"
      @reload="reload"
      @update-batch="updateMessage"
    />
    <send-message-template
      :show.sync="sendBulk"
      :customers.sync="sentSelectedCustomers"
      :resent-message="resentMessage"
      @success="refreshData"
    />
  </div>
</template>

<script>
import BulkMessagesFilter from "@/components/Communications/BulkMessages/BulkMessagesFilter";
import SendMessageTemplate from "@/components/Gardeners/Messaging/SendMessageTemplate";
import messages from "@/requests/communications/messages";
import * as actions from "@/store/action-types";
import BulkMessageInformation from "@/components/Communications/BulkMessages/BulkMessageInformation";

export default {
  name: "BulkMessage",
  components: {
    BulkMessageInformation,
    BulkMessagesFilter,
    SendMessageTemplate,
  },
  data() {
    return {
      period: "thisweek",
      custom: {
        from: null,
        to: null,
      },
      periods: {
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        thismonth: "This month",
      },
      loading: true,
      page: 1,
      pageData: [],
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      message: {},
      showBulkMessageInformation: false,
      sendBulk: false,
      sentSelectedCustomers: [],
      resentMessage: "",
    };
  },
  computed: {
    messages() {
      return this.$store.getters.bulk_messages;
    },
    from() {
      return this.messages.pages[this.page].from;
    },
    to() {
      return this.messages.pages[this.page].to;
    },
    perPage() {
      return this.messages.pages[this.page].perPage;
    },
    total() {
      return this.messages.total;
    },
    title() {
      return `${this.total || 0} Message Batches`;
    },
  },
  watch: {
    page() {
      this.getBulkMessages({
        period: this.period,
        from: this.custom.from,
        to: this.custom.to,
      });
    },
  },
  created() {
    this.getBulkMessages({ period: this.period });
  },
  methods: {
    refreshData(newMessage) {
      let data = this.parseData(this.pageData);
      data.unshift(newMessage);
      this.pageData = data;
    },
    getBulkMessages({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      let payload = {
        page: this.page,
        start_date,
        end_date,
      };

      this.$store
        .dispatch(actions.GET_BULK_MESSAGES, payload)
        .then(() => {
          this.setPageData();
          this.period = period;

          if (this.period === "custom") {
            this.custom.from = from;
            this.custom.to = to;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.parseData(this.messages.pages[this.page].data);
      this.showPagination = true;
      if (this.$route.params.id) {
        let recentMsg = this.pageData.filter((msg, index) => {
          if (this.$route.params.id == msg.id) {
            msg.msgIndex = index;
            return msg;
          }
        });
        this.viewMessage(recentMsg[0].msgIndex);
      }
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      let { start_date, end_date } = this.formatStartAndEndDate({
        period: this.period,
      });
      if (params.start_date && params.end_date) {
        start_date = params.start_date;
        end_date = params.end_date;
        delete params.start_date;
        delete params.end_date;
      }
      const constParams = `page=${this.page}&start_date=${start_date}&end_date=${end_date}`;
      let stringedParams;
      if (Object.keys(params).length) {
        stringedParams = `${constParams}&${this.sortObjectAsParams(params)}`;
      } else {
        stringedParams = constParams;
      }
      messages
        .filter(stringedParams)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          this.pageData = response.data.data;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;
      if (Object.keys(keys).length > 1) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.setPageData();
    },
    command(command) {
      const { index, action } = command;

      if (action === "view") {
        this.viewMessage(index);
      } else if (action === "resend") {
        this.resend(index);
      }
    },
    resend(index) {
      this.message = this.pageData[index];
      this.message.index = index;
      this.getBulkMessage();

      // let paramInfo = this.$route.params.message
      // let customers = paramInfo.recipients.map(item=>{
      //   return item.receiver
      // })
      // this.selectedCustomers = customers
      // this.command("send-bulk")
      // this.message= paramInfo.message
    },
    getBulkMessage() {
      const { created_at, id } = this.message;
      const start_date = this.formatDate(created_at, "y-m-d");
      this.loading = true;
      messages
        .message(start_date, id)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            const customers = data[0].recipients.map((item) => {
              return item.receiver;
            });
            return Promise.all(customers).then(() => {
              this.loading = false;
              this.sentSelectedCustomers = customers;
              this.resentMessage = data[0].message;
              this.sendBulk = true;
            });
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    viewMessage(index) {
      this.message = this.pageData[index];
      this.message.index = index;
      this.showBulkMessageInformation = true;
    },
    updateMessage(message) {
      this.pageData[this.message.index].status = message.status;
    },
    reload() {
      this.page = 1;
      this.getBulkMessages({ period: this.period });
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  max-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
