<template>
  <div class="batch-information--section">
    <div class="title">
      <p>{{ computedRecipients.length }} Customers</p>
      <el-dropdown :placement="'bottom'" @command="setRecipientFilter">
        <el-button type="plain">
          {{ recipientsFilters[recipientsFilter] }}
          <i class="eden-icon-arrow-down"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(value, key, index) in recipientsFilters"
            :key="index"
            :command="key"
            :class="{ selected: key === recipientsFilter }"
            >{{ value }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="recipients">
      <p v-if="status === 'pending'" class="pending">
        This message is still sending. <br />
        Check back in a few minutes to see the customers who received the
        message.
      </p>
      <template v-else>
        <template v-if="computedRecipients.length">
          <div
            v-for="(recipient, index) in computedRecipients"
            :key="index"
            class="recipient"
          >
            <p>
              {{ recipient.receiver && formatName(recipient.receiver.name) }}
            </p>
            <span
              :class="
                recipient.is_delivered === 1 ? 'text-primary' : 'text-danger'
              "
              >{{ statuses[recipient.is_delivered] }}</span
            >
          </div>
        </template>
        <p v-else class="pending">No recipient</p>
      </template>
    </div>
    <div v-if="computedFailedRecipients.length" class="action">
      <el-button type="primary" :loading="resending" @click="resendMessage"
        >Resend message to failed deliveries</el-button
      >
    </div>
  </div>
</template>

<script>
import messaging from "@/requests/gardeners/messaging";

export default {
  name: "BulkMessageRecipients",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: "",
    },
    status: {
      type: String,
      default: "pending",
    },
    message: {
      type: String,
      default: "",
    },
    recipients: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      statuses: {
        0: "Not delivered",
        1: "Delivered",
        2: "Customer not linked",
      },
      recipientsFilter: "all",
      recipientsFilters: {
        "all": "All recipients",
        "delivered": "Delivered",
        "not-delivered": "Not delivered",
        "not-linked": "Not linked",
      },
      resending: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return `${this.recipients.length} Customers`;
    },
    computedRecipients() {
      switch (this.recipientsFilter) {
        case "all":
          return this.recipients;
        case "not-delivered":
          return this.recipients.filter(
            (recipient) => recipient.is_delivered === 0,
          );
        case "delivered":
          return this.recipients.filter(
            (recipient) => recipient.is_delivered === 1,
          );
        case "not-linked":
          return this.recipients.filter(
            (recipient) => recipient.is_delivered === 2,
          );
        default:
          return [];
      }
    },
    computedFailedRecipients() {
      return this.computedRecipients.filter(
        (recipient) => recipient.is_delivered === 0,
      );
    },
  },
  watch: {
    id() {
      this.recipientsFilter = "all";
    },
  },
  methods: {
    setRecipientFilter(filter) {
      this.recipientsFilter = filter;
    },
    resendMessage() {
      this.resending = true;
      const sender_id = this.$store.getters.user.logged_in_userId;
      const receiver_ids = this.recipients
        .filter((recipient) => recipient.is_delivered === 0)
        .map((recipient) => recipient && recipient.receiver.id);

      let payload = {
        sender_id,
        receiver_ids,
        message: this.message,
      };

      messaging
        .sendMessage(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("message-resent");
            this.resending = false;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.resending = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.batch-information--section {
  margin-top: 30px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--eden-grey-septenary);

    p {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

.recipients {
  max-height: 200px;
  margin-bottom: 20px;
  overflow-y: scroll;

  p.pending {
    text-align: center;
    width: 80%;
    margin: 30px auto;
    font-size: 0.9rem;
    line-height: 1.6;
    color: var(--eden-grey-tertiary);
  }

  .recipient {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 0.875rem;
      color: var(--eden-grey-secondary);
    }

    span {
      font-size: 0.875rem;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.action {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
</style>
